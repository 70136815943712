import React, { Component } from "react";
import {
  HashRouter
} from "react-router-dom";
// import other components and hooks if needed

const images = [
  '/Images/1.jpg',
  '/Images/2.jpg',
  '/Images/3.jpg',
  '/Images/4.jpg',
  '/Images/5.jpg',
  '/Images/6.jpg',
  // Add paths to your images here
];

class Main extends Component {
  // Select a random image when the component mounts
  state = {
    randomImage: images[Math.floor(Math.random() * images.length)]
  };
  
  render() {
    return (
      <HashRouter>
          {/* Display the random image */}
          <img src={this.state.randomImage} alt="Random" />
      </HashRouter>
    );
  }
}

export default Main;
